#section-counter {
    position: relative;
    z-index: 0; }
    #section-counter .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #d9bf77;
      opacity: 0; }
  
  .ftco-counter {
    padding: 7em 0; }
    @media (max-width: 1199.98px) {
      .ftco-counter {
        background-position: center center !important; } }
    .ftco-counter .icon {
      width: 80px;
      height: 80px;
      margin: 0 auto;
      position: relative;
      margin-bottom: 20px;
      z-index: 0; }
      .ftco-counter .icon:after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        bordeR: 1px solid #d9bf77;
        z-index: -1;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        transition: all 0.3s ease; }
      .ftco-counter .icon span {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        color: #d9bf77; }
    .ftco-counter .text strong.number {
      font-weight: 500;
      font-size: 50px;
      color: #d9bf77; }
    .ftco-counter .text span {
      font-weight: 600;
      font-size: 18px ;
      color: #d9bf77;
      text-transform: uppercase;
      letter-spacing: 3px; }
    .ftco-counter .text:hover .icon span, .ftco-counter .text:focus .icon span {
      color: #000; }
    .ftco-counter .text:hover .icon:after, .ftco-counter .text:focus .icon:after {
      -webkit-transform: rotate(135deg);
      -ms-transform: rotate(135deg);
      transform: rotate(135deg);
      background: #d9bf77; }
    @media (max-width: 991.98px) {
      .ftco-counter .counter-wrap {
        margin-bottom: 40px !important; } }
    .ftco-counter .ftco-number {
      display: block;
      color: #fff; }
    .ftco-counter .ftco-label {
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: .1em; }

      .ftco-counter .text strong.number span{
           font-size: 50px;
      }

