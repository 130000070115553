:root {
  --default-font: "Roboto",  system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --heading-font: "Playfair Display",  sans-serif;
  --nav-font: "Poppins",  sans-serif;
}

/* Global Colors - The following color variables are used throughout the website. Updating them here will change the color scheme of the entire website */
:root { 
  --background-color: #0c0b09; /* Background color for the entire website, including individual sections */
  --default-color: rgba(255, 255, 255, 0.7); /* Default color used for the majority of the text content across the entire website */
  --heading-color: #ffffff; /* Color for headings, subheadings and title throughout the website */
  --accent-color: #cda45e; /* Accent color that represents your brand on the website. It's used for buttons, links, and other elements that need to stand out */
  --surface-color: #29261f; /* The surface color is used as a background of boxed elements within sections, such as cards, icon boxes, or other elements that require a visual separation from the global background. */
  --contrast-color: #0c0b09; /* Contrast color for text, ensuring readability against backgrounds of accent, heading, or default colors. */
}

/* Nav Menu Colors - The following color variables are used specifically for the navigation menu. They are separate from the global colors to allow for more customization options */
:root {
  --nav-color: #ffffff;  /* The default color of the main navmenu links */
  --nav-hover-color: #cda45e; /* Applied to main navmenu links when they are hovered over or active */
  --nav-mobile-background-color: #29261f; /* Used as the background color for mobile navigation menu */
  --nav-dropdown-background-color: #29261f; /* Used as the background color for dropdown items that appear when hovering over primary navigation items */
  --nav-dropdown-color: #ffffff; /* Used for navigation links of the dropdown items in the navigation menu. */
  --nav-dropdown-hover-color: #cda45e; /* Similar to --nav-hover-color, this color is applied to dropdown navigation links when they are hovered over. */
}

/* Color Presets - These classes override global colors when applied to any section or element, providing reuse of the sam color scheme. */

.light-background {
  --background-color: #29261f;
  --surface-color: #464135;
}

.dark-background {
  --background-color: #000000;
  --default-color: #ffffff;
  --heading-color: #ffffff;
  --surface-color: #1a1a1a;
  --contrast-color: #ffffff;
}

/* Smooth scroll */
:root {
  scroll-behavior: smooth;
}


/*--------------------------------------------------------------
# Global Header
--------------------------------------------------------------*/
.header {
  --background-color: rgba(250, 247, 239, 0.3) !important;
  color: #ffffff;
  transition: all 0.5s;
  z-index: 997;
  top: 10px !important;
}

.header .topbar {
  height: 40px;
  padding: 0;
  font-size: 14px;
  transition: all 0.5s;
}

.header .topbar .contact-info i {
  font-style: normal;
  color: var(--accent-color);
}

.header .topbar .contact-info svg a,
.header .topbar .contact-info svg span {
  padding-left: 5px;
  color: #ffffff;
}

@media (max-width: 575px) {

  .header .topbar .contact-info svg a,
  .header .topbar .contact-info svg span {
    font-size: 13px;
  }
}

.header .topbar .contact-info svg a {
  line-height: 0;
  transition: 0.3s;
}

.header .topbar .contact-info svg a:hover {
  color: var(--accent-color);
  text-decoration: underline;
}

.header .topbar .languages ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  color: var(--accent-color);
}

.header .topbar .languages ul a {
  color: #ffffff;
}

.header .topbar .languages ul a:hover {
  color: var(--accent-color);
}

.header .topbar .languages ul li+li {
  padding-left: 10px;
}

.header .topbar .languages ul li+li::before {
  display: inline-block;
  padding-right: 10px;
  color: color-mix(in srgb, #ffffff, transparent 10%);
  content: "/";
}

.header .branding {
  background-color: var(--background-color);
  min-height: 60px;
  padding: 10px 0;
  transition: 0.3s;
  /* border-bottom: 1px solid var(--background-color); */
}

.header .logo {
  line-height: 1;
}

.header .logo img {
  max-height: 80px;
  margin-right: 8px;
}

.header .logo h1 {
  font-size: 30px;
  margin: 0;
  font-weight: 700;
  color: var(--heading-color);
}

.header .btn-book-a-table,
.header .btn-book-a-table:focus {
  color: #ffffff;
  border: 2px solid var(--accent-color);
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  padding: 6px 24px;
  margin: 0 5px 0 30px;
  border-radius: 50px;
  transition: 0.3s;
  text-decoration: none;
}

.header .btn-book-a-table:hover,
.header .btn-book-a-table:focus:hover {
  color: #ffffff;
  font-weight: 700;
  background: var(--accent-color);
}


.scrolled .header .btn-book-a-table,
.scrolled .header .btn-book-a-table:focus {
  color: #ffffff;
  background-color: #d9bf77;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  padding: 6px 24px;
  margin: 0 5px 0 30px;
  border-radius: 50px;
  transition: 0.3s;
  text-decoration: none;
}

.scrolled .header .btn-book-a-table:hover,
.scrolled .header .btn-book-a-table:focus:hover {
  color: #ffffff;
  background: var(--accent-color);
  font-weight: 700;
}

.scrolled .header .topbar {
  height: 0;
  visibility: hidden;
  overflow: hidden;
}

.scrolled .header .branding {
  border-color: color-mix(in srgb, var(--accent-color), transparent 80%);
}

/* Global Header on Scroll
------------------------------*/
.scrolled .header {
  --background-color: #fafafa !important;
  top: 0px !important;

}
/* .scrolled .header .navmenu li a{
  color:#000;
} */
/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Navmenu - Desktop */

@media (min-width: 1200px) {
  .navmenu {
    padding: 0;
  }

  .navmenu ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }

  .navmenu li {
    position: relative;
  }

  .navmenu a,
  .navmenu a:focus {
    color: #000;
    padding: 18px 15px;
    font-size: 14px;
    font-family: "Poppins",  sans-serif;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    transition: 0.3s;
    text-decoration: none;
    font-weight: 600;
  }

  .navmenu a i,
  .navmenu a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
    transition: 0.3s;
  }

  .navmenu li:last-child a {
    padding-right: 0;
  }

  .navmenu li:hover>a,
  .navmenu .active,
  .navmenu .active:focus {
    color: var(--nav-hover-color);
  }

  .navmenu .dropdown ul {
    margin: 0;
    padding: 10px 0;
    background: var(--nav-dropdown-background-color);
    display: block;
    position: absolute;
    visibility: hidden;
    left: 14px;
    top: 130%;
    opacity: 0;
    transition: 0.3s;
    border-radius: 4px;
    z-index: 99;
  }

  .navmenu .dropdown ul li {
    min-width: 200px;
  }

  .navmenu .dropdown ul a {
    padding: 10px 20px;
    font-size: 15px;
    text-transform: none;
    color: var(--nav-dropdown-color);
  }

  .navmenu .dropdown ul a i {
    font-size: 12px;
  }

  .navmenu .dropdown ul a:hover,
  .navmenu .dropdown ul .active:hover,
  .navmenu .dropdown ul li:hover>a {
    color: var(--nav-dropdown-hover-color);
  }

  .navmenu .dropdown:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }

  .navmenu .dropdown .dropdown ul {
    top: 0;
    left: -90%;
    visibility: hidden;
  }

  .navmenu .dropdown .dropdown:hover>ul {
    opacity: 1;
    top: 0;
    left: -100%;
    visibility: visible;
  }
}

/* Navmenu - Mobile */
@media (max-width: 1199px) {
  .mobile-nav-toggle {
    color: #d5c07a;
    font-size: 28px;
    line-height: 0;
    margin-right: 10px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .navmenu {
    padding: 0;
    z-index: 9997;
  }

  .navmenu ul {
    display: none;
    position: absolute;
    inset: 60px 20px 20px 20px;
    padding: 10px 0;
    margin: 0;
    border-radius: 6px;
    background-color: var(--nav-mobile-background-color);
    border: 1px solid color-mix(in srgb, #ffffff, transparent 90%);
    overflow-y: auto;
    transition: 0.3s;
    z-index: 9998;
  }

  .navmenu a,
  .navmenu a:focus {
    color: var(--nav-dropdown-color);
    padding: 10px 20px;
    font-family: "Poppins",  sans-serif;
    font-size: 17px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    transition: 0.3s;
    text-decoration: none;
  }

  .navmenu a i,
  .navmenu a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: 0.3s;
    background-color: color-mix(in srgb, var(--accent-color), transparent 90%);
  }

  .navmenu a i:hover,
  .navmenu a:focus i:hover {
    background-color: var(--accent-color);
    color: var(--contrast-color);
  }

  .navmenu a:hover,
  .navmenu .active,
  .navmenu .active:focus {
    color: var(--nav-dropdown-hover-color);
  }

  .navmenu .active i,
  .navmenu .active:focus i {
    background-color: var(--accent-color);
    color: var(--contrast-color);
    transform: rotate(180deg);
  }

  .navmenu .dropdown ul {
    position: static;
    display: none;
    z-index: 99;
    padding: 10px 0;
    margin: 10px 20px;
    background-color: var(--nav-dropdown-background-color);
    border: 1px solid color-mix(in srgb, #ffffff, transparent 90%);
    box-shadow: none;
    transition: all 0.5s ease-in-out;
  }

  .navmenu .dropdown ul ul {
    background-color: rgba(33, 37, 41, 0.1);
  }

  .navmenu .dropdown>.dropdown-active {
    display: block;
    background-color: rgba(33, 37, 41, 0.03);
  }

  .mobile-nav-active {
    overflow: hidden;
  }

  .mobile-nav-active .mobile-nav-toggle {
    color: #fff;
    position: absolute;
    font-size: 32px;
    top: 15px;
    right: 15px;
    margin-right: 0;
    z-index: 9999;
  }

  .mobile-nav-active .navmenu {
    position: fixed;
    overflow: hidden;
    inset: 0;
    background: rgba(33, 37, 41, 0.8);
    transition: 0.3s;
  }

  .mobile-nav-active .navmenu>ul {
    display: block;
  }
}

/* .header.scrolled.awake{
  background-color: #faf7ef !important;
} */