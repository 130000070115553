/* .ftco-footer {
  font-size: 16px;
  padding: 3rem 0 !important;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.ftco-footer .ftco-footer-logo {
  text-transform: uppercase;
  letter-spacing: .1em;
}

.ftco-footer .ftco-footer-widget h2 {
  font-weight: normal;
  color: #d9bf77;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 600;
}

.ftco-footer .ftco-footer-widget ul li a span {
  color: #d9bf77;
}

.ftco-footer .ftco-footer-widget .btn-primary {
  background: #fff !important;
  border: 2px solid #fff !important;
}

.ftco-footer .ftco-footer-widget .btn-primary:hover {
  background: #fff;
  border: 2px solid #fff !important;
}

.ftco-footer a {
  color: #d9bf77;
  text-decoration: none;
}

.ftco-footer a:hover {
  color: #000;
}

.ftco-footer .ftco-heading-2 {
  font-size: 17px;
  font-weight: 400;
  color: #000;
}

.ftco-footer .categories li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.ftco-footer .categories li a span {
  color: rgba(255, 255, 255, 0.5) !important;
}

.ftco-footer-social li {
  list-style: none;
  margin: 0 10px 0 0;
  display: inline-block;
}

.ftco-footer-social li a {
  height: 40px;
  width: 40px;
  display: block;
  background: #d9bf77;
  border-radius: 50%;
  position: relative;
  color: #d9bf77 !important;
}

.ftco-footer-social li a span {
  position: absolute;
  font-size: 20px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #fff !important;
}

.ftco-footer-social li a:hover {
  color: #fff;
}

#map {
  width: 100%;
}

#map.map {
  height: 400px;
}

@media (max-width: 991.98px) {
  #map {
    height: 300px;
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(217, 191, 119, 0.2);
  }

  70% {
    -webkit-box-shadow: 0 0 0 30px rgba(217, 191, 119, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(217, 191, 119, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(217, 191, 119, 0.2);
    -webkit-box-shadow: 0 0 0 0 rgba(217, 191, 119, 0.2);
    box-shadow: 0 0 0 0 rgba(217, 191, 119, 0.2);
  }

  70% {
    -moz-box-shadow: 0 0 0 30px rgba(217, 191, 119, 0);
    -webkit-box-shadow: 0 0 0 30px rgba(217, 191, 119, 0);
    box-shadow: 0 0 0 30px rgba(217, 191, 119, 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(217, 191, 119, 0);
    -webkit-box-shadow: 0 0 0 0 rgba(217, 191, 119, 0);
    box-shadow: 0 0 0 0 rgba(217, 191, 119, 0);
  }
}
 */

 /*--------------------------------------------------------------
# Global Footer
--------------------------------------------------------------*/
.footer {
  color: var(--default-color);
  background-color: #000000;
  font-size: 14px;
  position: relative;
}

.footer .footer-top .footer-about .logo img {
  max-height: 80px;
  margin-right: 8px;
}

.footer .footer-top {
  padding: 50px 0;
  background-color: color-mix(in srgb, var(--footer-background-color) 90%, white 10%);
}

.footer .footer-about .logo {
  line-height: 1;
  margin-bottom: 10px;
  text-decoration: none;
}

.footer .footer-about .logo img {
  max-height: 40px;
  margin-right: 6px;
}

.footer .footer-about .logo span {
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 1px;
  font-family: "Open Sans", Arial, sans-serif !important;
  color: #d9bf77;
}

.footer .footer-about p {
  font-size: 14px;
  font-family: var(--heading-font);
}

.footer .social-links a {
  color: #d9bf77;
  background-color: color-mix(in srgb, var(--default-color) 5%, white 10%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  font-size: 16px;
  margin-right: 10px;
  transition: 0.3s;
}

.footer .social-links a:hover {
  color: #d9bf77;
  background-color: var(--accent-color);
}

.footer h4 {
  font-size: 16px;
  font-weight: bold;
  position: relative;
  padding-bottom: 12px;
}

.footer .footer-links {
  margin-bottom: 30px;
}

.footer .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer .footer-links ul i {
  margin-right: 4px;
  font-size: 12px;
  line-height: 0;
  color: var(--accent-color);
}

.footer .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.footer .footer-links ul li:first-child {
  padding-top: 0;
}

.footer .footer-links ul a {
  color: #d9bf77;
  display: inline-block;
  line-height: 1;
  text-decoration: none;
}

.footer .footer-links ul a:hover {
  color: var(--accent-color);
}

.footer .footer-contact p {
  margin-bottom: 5px;
}

.footer .footer-newsletter .newsletter-form {
  margin-top: 30px;
  margin-bottom: 15px;
  padding: 6px 8px;
  position: relative;
  border-radius: 4px;
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 80%);
  display: flex;
  background-color: color-mix(in srgb, var(--footer-background-color) 90%, white 15%);
  transition: 0.3s;
}

.footer .footer-newsletter .newsletter-form:focus-within {
  border-color: var(--accent-color);
}

.footer .footer-newsletter .newsletter-form input[type=email] {
  border: 0;
  padding: 4px;
  width: 100%;
  background-color: color-mix(in srgb, var(--footer-background-color) 90%, white 15%);
  color: var(--default-color);
}

.footer .footer-newsletter .newsletter-form input[type=email]:focus-visible {
  outline: none;
}

.footer .footer-newsletter .newsletter-form input[type=submit] {
  border: 0;
  font-size: 16px;
  padding: 0 20px;
  margin: -7px -8px -7px 0;
  background: var(--accent-color);
  color: #d9bf77;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
}

.footer .footer-newsletter .newsletter-form input[type=submit]:hover {
  background: color-mix(in srgb, var(--accent-color), transparent 20%);
}

.footer .copyright {
margin-left: 10%;
margin-right: 10%;
border-top: 1px solid #fff;
  padding: 30px 0;
}

.footer .copyright p {
  margin-bottom: 0;
}

.footer .credits {
  margin-top: 5px;
  font-size: 13px;
}

.brand{
  text-decoration: none;
  color: #d9bf77;
}

.call{
  color: #d9bf77;
}